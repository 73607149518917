import React from 'react';
import ModalForm from '../../../Components/ModalForm/ModalForm';
import { EditBase, minValue, required, useRecordContext, useTranslate } from 'react-admin';
import { TextInputCustom } from '../../../Components/InputsCustom/TextInputCustom/TextInputCustom';
import NumberInputCustom from '../../../Components/InputsCustom/NumberInputCustom/NumberInputCustom';
import { useSelector } from 'react-redux';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Button, InputAdornment } from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import { useFormContext } from 'react-hook-form';
import { formatMount } from '../../../helpers/multiuso';


const EditTransaction = () => {
  const { currency } = useSelector(state => state.config);
  const translate = useTranslate();

  return (
    <EditBase mutationMode='pessimistic'>
      <ModalForm title={translate('activity_resource.refund.title')} source='/transactions'>

        <TextInputCustom source="hash" label={'activity_resource.refund.pay_id'} disabled />

        <InputFormatMount currency={currency} />

        <InputFormatRefund currency={currency} />

        <TextInputCustom
          source='note'
          label={'note'}
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <NotesIcon sx={{ fontSize: 21, color: 'primary.main' }} />
            </InputAdornment>,
          }}
        />

      </ModalForm>
    </EditBase>
  );
};

const InputFormatMount = ({ currency }) => {
  const record = useRecordContext();

  const handleFormatMount = () => {
    return (record ? formatMount(record[currency.toLowerCase()], currency) : null);
  };

  return (
    <TextInputCustom
      source={currency.toLowerCase()}
      label={'activity_resource.refund.sell_mount'}
      disabled
      format={handleFormatMount}
      InputProps={{
        startAdornment: <InputAdornment position="start">
          <AttachMoneyIcon sx={{ fontSize: 21, color: 'primary.main' }} />
        </InputAdornment>,
      }}
    />
  );
};

const InputFormatRefund = ({ currency }) => {
  const record = useRecordContext();
  const { setValue, formState } = useFormContext();
  const translate = useTranslate();

  const handleClickTotalRefund = () => {
    setValue('refunded_amount', formState.defaultValues.ars);
  };

  const formatRefund = (val) => {
    if (!record) return 0;

    if (currency === 'USD') {
      return (val / record.usd_ars).toFixed(2);
    } else {
      return formatMount(val, currency);
    }
  };

  const validateMount = (value) => {
    if (value < 1) {
      return translate('mount_refund_error');
    }
    return undefined;
  };

  return (
    <NumberInputCustom
      source='refunded_amount'
      label={'activity_resource.refund.refund_mount'}
      validate={[required(), validateMount]}
      format={formatRefund}
      InputProps={{
        startAdornment: <InputAdornment position="start">
          <AttachMoneyIcon sx={{ fontSize: 21, color: 'primary.main' }} />
        </InputAdornment>,
        endAdornment: <InputAdornment position="end">
          <Button size='small' variant='text' onClick={handleClickTotalRefund}>total</Button>
        </InputAdornment>
      }}
    />
  );
};

export default EditTransaction;