import React from 'react';
import { useNotify } from 'react-admin';

const useCopyClipboard = () => {
  const notify = useNotify();

  const copy = async (textToCopy, msg = 'Copiado al portapapeles') => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      notify(msg, { type: 'success', autoHideDuration: 800 });
    } catch (err) {
      console.error('Error al copiar al portapapeles:', err);
    }
  };
  return (copy);
};

export default useCopyClipboard;