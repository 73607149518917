import { Alert, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { TextFieldCustom } from '../../Components/InputsCustom/TextInputCustom/TextInputCustom';
import { useLogin, useTranslate } from 'react-admin';
import dataProvider from '../../dataProvider';
import useEmailAuth from '../../Hooks/useEmailAuth';
import WrapperSimpleForm from '../../Components/ModalForm/WrapperSimpleForm';

const invalidMessage = {
  data: { msg: 'This link was already used before, please try another one.', status: 'error' },
  res: { ok: true }
};


const ModalLoginCreateUser = () => {
  const translate = useTranslate();
  const [createUser, setCreateUser] = useState(false);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', minWidth: 840, flexDirection: 'column', alignItems: 'center', paddingTop: '90px' }}>

      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
        onClick={() => window.open('https://bitcoincashargentina.com/', '_blank')}
      >
        <img src={require('../../assets/images/bchSupport.png')} style={{ width: '70px', objectFit: 'contain' }} />
      </Box>
      <Box
        sx={{
          width: '60vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '21px',
          zIndex: 99
        }}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: 3,
            padding: '27px 21px'
          }}
        >
          <Typography
            variant='h6'
            sx={{ width: '100%', color: '#383838', textAlign: 'center', fontWeight: 500 }}
          >
            {createUser ? translate('create_user') : translate('sign_in')}
          </Typography>
          {
            createUser ?
              <CreateUserForm setCreateUser={setCreateUser} /> :
              <LoginForm setCreateUser={setCreateUser} />

          }
        </Box>
      </Box>
    </Box>
  );
};

const CreateUserForm = ({ setCreateUser }) => {
  const [toggleButton, setToggleButton] = useState(false);
  const [show, setShow] = useState(null);
  const translate = useTranslate();

  const res = {
    success: {
      success: true,
      msg: translate('user_created_success')
    },
    error: {
      success: false,
      msg: translate('user_created_error')
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setToggleButton(true);
    const data = new FormData(event.currentTarget);
    const userName = data.get('name');
    const userEmail = data.get('email');

    await dataProvider.create('users/register', { data: { name: userName, email: userEmail, role: 'manager' } })
      .then(e => {
        setToggleButton(false);
        setShow(res.success);
      })
      .catch(e => {
        setToggleButton(false);
        setShow(res.error);
      });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} style={{ marginTop: '10px', width: '300px' }}>
      <TextFieldCustom
        disabled={toggleButton}
        margin="normal"
        variant='outlined'
        required
        fullWidth
        id="name"
        size='small'
        color='primary'
        label={translate('name')}
        name="name"
        autoFocus
      />
      <TextFieldCustom
        disabled={toggleButton}
        margin="normal"
        variant='outlined'
        required
        fullWidth
        id="email"
        size='small'
        color='primary'
        label="Email"
        name="email"
        autoComplete="email"
      />
      {
        show ?
          <Alert variant="filled" severity={show.success ? 'success' : 'error'}>
            {show.msg ? show.msg : translate('user_created_error')}
          </Alert>
          : null
      }
      <Button
        disabled={toggleButton}
        fullWidth
        variant="contained"
        type='submit'
        color='secondary'
        style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}
      >
        {toggleButton ?
          <CircularProgress size={24} /> :
          <Typography style={{ textAlign: 'center', width: '100%' }} variant='subtitle2'>{translate('create_user')}</Typography>
        }
      </Button>

      <Box sx={{ marginTop: '36px', display: 'flex', flexDirection: 'column', borderTop: '1px solid #9c9c9c', paddingTop: '21px' }}>
        <Typography sx={{ color: 'gray', textAlign: 'center', marginBottom: '12px' }}>{translate('has_account')}</Typography>
        <Button size='small' variant='outlined' sx={{ marginTop: 1 }} onClick={() => setCreateUser(false)}>{translate('sign_in_other_email')}</Button>
      </Box>
    </Box>
  );
};

const LoginForm = ({ setCreateUser }) => {
  const [toggleButton, setToggleButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(null);
  const emailAuth = useEmailAuth();
  const isAuthLink = window.location.href.includes('token_url');
  const login = useLogin();
  const translate = useTranslate();

  const statusMessage = {
    201: {
      data: { msg: translate('email_send_success'), status: 'success' },
      res: { ok: true }
    },
    404: {
      data: { msg: translate('email_not_found'), status: 'error' },
      res: { ok: true }
    }
  };

  const authWithToken = () => {
    const hash = window.location.hash;
    const tokenUrl = hash?.split('?')[1]?.split('=')[1];

    if (tokenUrl == null) {
      return;
    }
    login(tokenUrl).then(() => setLoading(false)).catch(e => {
      setShow(invalidMessage);
      setLoading(false);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setToggleButton(true);
    const data = new FormData(event.currentTarget);
    const r = await emailAuth(data.get('email'));

    setShow(statusMessage[r.res?.status]);
    setToggleButton(false);
  };


  React.useEffect(() => {
    if (isAuthLink) {
      setLoading(true);
      authWithToken();
    }
  }, [isAuthLink]);

  return (
    <Box component="form" onSubmit={handleSubmit} style={{ marginTop: '10px', width: '300px' }}>
      <TextFieldCustom
        disabled={toggleButton}
        margin="normal"
        variant='outlined'
        required
        fullWidth
        id="email"
        size='small'
        color='primary'
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
      />
      {
        show ?
          <Alert variant="filled" severity={show.res.ok ? show.data.status : 'error'}>
            {show.res.ok ? show.data.msg : 'No se encontro el email'}
          </Alert>
          : null
      }
      <Button
        disabled={toggleButton}
        fullWidth
        variant="contained"
        type='submit'
        color='secondary'
        style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}
      >
        {toggleButton ?
          <CircularProgress size={24} /> :
          <Typography style={{ textAlign: 'center', width: '100%' }} variant='subtitle2'>{translate('sign_in')}</Typography>
        }
      </Button>

      <Box sx={{ marginTop: '36px', display: 'flex', flexDirection: 'column', borderTop: '1px solid #9c9c9c', paddingTop: '21px' }}>
        <Typography sx={{ color: 'gray', textAlign: 'center', marginBottom: '12px' }}>{translate('new_user')}</Typography>
        <Button size='small' variant='outlined' sx={{ marginTop: 1 }} onClick={() => setCreateUser(true)}>{translate('create_user')}</Button>
      </Box>
    </Box>
  );
};

export default ModalLoginCreateUser;