import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslate } from 'react-admin';

const CustomEmpty = ({ title }) => {
  const translate = useTranslate();

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography>{translate(title)}</Typography>
    </Box>
  );
};

export default CustomEmpty;