import React from 'react';
import { CreateBase } from 'react-admin';
import FormUser from './FormUser';
import { transform } from './transform';

const CreateUser = () => {
  return (
    <CreateBase redirect='list' transform={transform}>
      <FormUser title={'user_resource.create.title'} />
    </CreateBase>
  );
};

export default CreateUser;