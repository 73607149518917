import { Box } from '@mui/material';
import React from 'react';
import { useTheme } from 'react-admin';
import { COLORS } from '../../assets/constants/theme';

const CardDefault = ({ children, variant = 'primary', small = false, style, ...rest }) => {
  const theme = useTheme()[0];
  const bg = variant == 'primary' ? COLORS[theme]?.primary.main : COLORS[theme]?.primary.light;

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        padding: small ? '6px 15px' : '15px 24px',
        borderRadius: 3,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        ...style
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default CardDefault;