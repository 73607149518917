import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingCustom = () => {
  return (
    <Box sx={{ width: '100%', height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress color='secondary' />
    </Box>
  );
};

export default LoadingCustom;