import React from 'react';
import { Logout, UserMenu } from 'react-admin';

const UserMenuCustom = () => {
  return (
    <UserMenu>
      <Logout
        sx={{
          color: '#fff',
          width: 120,
          '& .MuiListItemIcon-root': {
            color: '#fff',
          }
        }} />
    </UserMenu>
  );
};

export default UserMenuCustom;