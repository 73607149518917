
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  merchants: [],
  selectedMerchant: null
};

const branchSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    setMerchants: (state, action) => {
      state.merchants = action.payload;
    },
    setSelectedMerchant: (state, action) => {
      state.selectedMerchant = action.payload;
    },
  },
});

export const { setMerchants, setSelectedMerchant } = branchSlice.actions;

export default branchSlice.reducer;