import React from 'react';
import { ListBase, useTranslate } from 'react-admin';
import TopToolbarList from '../../Components/TopToolbarList/TopToolbarList';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import DoubleRow from '../../Components/CustomFields/DoubleRow/DoubleRow';
import ActionsField from '../../Components/CustomFields/ActionsField/ActionsField';
import TextField from '../../Components/CustomFields/TextField/TextField';
import WrapperComponent from '../../Components/WrapperComponent/WrapperComponent';

const ListUser = () => {
  const translate = useTranslate();

  return (
    <WrapperComponent>
      <ListBase perPage={999}>
        <TopToolbarList title={translate('user_resource.title.list')} hasCreate labelBtn={translate('user_resource.create.button')} />
        <DatagridCustom>
          <DoubleRow primarySrc={'name'} secondarySrc={'email'} source={'Usuario'} label={translate('user_resource.title.singular')} sortable={false} />
          <TextField source={'role'} label={'Rol'} variant='secondary' style={{ minWidth: 200 }} sortable={false} />
          <ActionsField title={'Usuario'} resource={'users'} />
        </DatagridCustom>
      </ListBase>
    </WrapperComponent>
  );
};

export default ListUser;