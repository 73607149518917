import { Select } from '@mui/material';
import React from 'react';

const SelectMui = ({ children, ...rest }) => {
  return (
    <Select
      sx={{
        width: 190,
        height: 33,
        backgroundColor: '#fff',
        borderRadius: 2,
        borderColor: '#fff',
        color: 'gray',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#fff',
          borderRadius: 2,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#fff'
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#fff'
        },
        '& .MuiSvgIcon-root-MuiSelect-icon': {
          color: 'rgba(0,0,0,.54)'
        },
        '& .MuiSelect-icon': {
          color: 'rgba(0,0,0,.54)'
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: 2,
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: 'transparent',
          },
        },
      }}
      {...rest}
    >
      {children}
    </Select>
  );
};

export default SelectMui;