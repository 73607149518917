import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useListContext, useRefresh } from 'react-admin';
import RefreshIcon from '@mui/icons-material/Refresh';

const RefreshButton = () => {
  const [loading, setLoading] = useState(false);

  const refresh = useRefresh();
  const { isFetching, isLoading } = useListContext();

  useEffect(() => {
    console.log('listContext', isLoading || isFetching);
    setLoading(isLoading || isFetching);
  }, [isLoading, isFetching]);

  const handleRefresh = () => {
    refresh();
  };

  return (
    <IconButton aria-label="refresh" onClick={handleRefresh} color='secondary' size="large">
      <RefreshIcon sx={{
        animation: loading && 'spin 1s linear infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        }
      }} />
    </IconButton>
  );
};

export default RefreshButton;