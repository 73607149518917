
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  branches: [],
  categories: [],
  selectedBranch: null
};

const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    setBranches: (state, action) => {
      state.branches = action.payload;
    },
    setSelectedBranch: (state, action) => {
      state.selectedBranch =  action.payload;
    },
    setCategories: (state, action) => {
      state.categories =  action.payload;
    },
  },
});

export const { setBranches, setSelectedBranch, setCategories } = branchSlice.actions;

export default branchSlice.reducer;