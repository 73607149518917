import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ButtonFilter from '../../../Components/ButtonFilter/ButtonFilter';
import ModalDateRangePicker from '../../../Components/DateRangePickerCustom/ModalDateRangePicker';
import useDebounce from '../../../Hooks/useDebounce';
import { useTranslate } from 'react-admin';

const datesRanges = {
  'HOY': 'today',
  'SEMANAL': 'week',
  'MENSUAL': 'month',
  'ANUAL': 'year'
};

const FiltersDashboard = ({ filters, setFilters }) => {
  const [openPicker, setOpenPicker] = useState(false);
  const [activeFilter, setActiveFilter] = useState('HOY');
  const [localFilters, setLocalFilter] = useState(filters);
  const debounceFilter = useDebounce(localFilters, 900);
  const translate = useTranslate();

  useEffect(() => {
    setFilters(localFilters);
  }, [debounceFilter]);

  const handleClick = (e) => {
    const name = e.target.name;
    setActiveFilter(name);
    if (name === 'PERSONALIZADO') {
      setOpenPicker(true);
    } else {
      const type = datesRanges[name];
      setFilters(prev => ({ ...prev, type }));
    }
  };

  const handleSelectDatePicker = ({ date }) => {
    setLocalFilter({ ...filters, startDate: date.startDate, endDate: date.endDate, type: 'custom' });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, height: 21 }}>

      <ButtonFilter title={translate('today')} name={'HOY'} active={activeFilter == 'HOY'} onClick={handleClick} />
      <ButtonFilter title={translate('weekly')} name={'SEMANAL'} active={activeFilter == 'SEMANAL'} onClick={handleClick} />
      <ButtonFilter title={translate('monthly')} name={'MENSUAL'} active={activeFilter == 'MENSUAL'} onClick={handleClick} />
      <ButtonFilter title={translate('annual')} name={'ANUAL'} active={activeFilter == 'ANUAL'} onClick={handleClick} />
      <ButtonFilter title={translate('personalized')} name={'PERSONALIZADO'} active={activeFilter == 'PERSONALIZADO'} onClick={handleClick} />

      <ModalDateRangePicker
        setOpen={setOpenPicker}
        open={openPicker}
        ranges={{ startDate: localFilters.startDate, endDate: localFilters.endDate, key: 'date' }}
        handleSelect={(date) => handleSelectDatePicker({ date, type: 'custom' })}
        maxDate={new Date()}
      />

    </Box>
  );
};


export default FiltersDashboard;