import React from 'react';
import { ListBase, usePermissions, useTranslate } from 'react-admin';
import TopToolbarList from '../../Components/TopToolbarList/TopToolbarList';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import DoubleRow from '../../Components/CustomFields/DoubleRow/DoubleRow';
import ActionsField from '../../Components/CustomFields/ActionsField/ActionsField';
import TextField from '../../Components/CustomFields/TextField/TextField';
import dataProvider from '../../dataProvider';
import { useDispatch } from 'react-redux';
import { setBranches } from '../../Store/branchReducer';
import WrapperComponent from '../../Components/WrapperComponent/WrapperComponent';
import { setMerchants } from '../../Store/merchantReducer';

const params = {
  pagination: { perPage: 999, page: 1 },
  sort: { field: 'name', order: 'ASC' },
  filter: {}
};

const ListBranch = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { permissions } = usePermissions();

  const onSuccess = () => {
    dataProvider.getCustom('branches')
      .then(({ results }) => {
        dispatch(setBranches(results));
      });

    dataProvider.getList('merchants', params).then(({ data }) => {
      dispatch(setMerchants(data));
    });
  };

  return (
    <WrapperComponent>
      <ListBase perPage={999}>
        <TopToolbarList title={translate('branch_resource.title')} hasCreate labelBtn={translate('branch_resource.create.button')} />
        <DatagridCustom>
          <DoubleRow primarySrc={'name'} secondarySrc={'address'} source={'Sucursal'} label={translate('branch_resource.field.branch')} sortable={false} />
          <TextField source={'bch_address'} label={translate('branch_resource.field.bch_address')} variant={'secondary'} width={300} enableCopy sortable={false} />
          <ActionsField title={'Sucursal'} resource={'branches'} deleteMutationOptions={{ onSuccess }} hasDelete={permissions !== 'cashier'} />
        </DatagridCustom>
      </ListBase>
    </WrapperComponent>
  );
};

export default ListBranch;