import React from 'react';
import { EditBase } from 'react-admin';
import FormUser from './FormUser';
import { transform } from './transform';

const EditUser = () => {
  return (
    <EditBase redirect='list' transform={transform}>
      <FormUser title={'user_resource.edit.title'} isEdit />
    </EditBase>
  );
};

export default EditUser;