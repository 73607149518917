import { TextField, useTheme } from '@mui/material';
import React from 'react';

const FilterInput = ({ inputProps, type = 'input', placeholder, sx, color = '#2e2e2e', ...rest }) => {
  const theme = useTheme();

  return (
    <TextField
      label=""
      variant="outlined"
      type={type}
      placeholder={placeholder}
      size='small'
      InputProps={inputProps}
      sx={{
        color: '#000',
        '& label.Mui-focused': {
          color: '#000',
        },
        '& label': {
          color: '#000',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'gray',
        },
        '& .MuiOutlinedInput-input': {
          '&::-webkit-calendar-picker-indicator': {
            marginTop: '2px',
            filter: theme.palette.mode == 'dark' ? 'invert(1)' : 'none'
          },
          '&::-webkit-datetime-edit': {
            paddingLeft: '12px',
            color: 'gray',
          },
          '&:focus::-webkit-datetime-edit': {
            color: '#000'
          },
          color: color,
          zIndex: 9,
          padding: '6px 6px 6px 1px',
          fontSize: '14px',
          fontWeight: '500',
        },
        '& .MuiOutlinedInput-root': {
          color: '#000',
          paddingLeft: '6px',
          '& .Mui-disabled': {
            WebkitTextFillColor: 'gray'
          },
          '& fieldset': {
            borderRadius: 2,
            borderColor: 'transparent',
            backgroundColor: '#fff',
          },
          '&:hover fieldset': {
            borderColor: 'transparent',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'transparent'
          },
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: '#fff',
          color: '#000'
        },
        ...sx,
      }}
      {...rest}
    />
  );
};

export default FilterInput;