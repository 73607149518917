import {apiUrl} from '../dataProvider/index';

const useEmailAuth = () => {

  const emailAuth = async (email) => {
    const request = new Request(`${apiUrl}/login?email=${encodeURIComponent(email)}`,{
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    });
    const res = await fetch(request);
    const data = await res.json();
    
    return {data, res};
  };

  return emailAuth;
};

export default useEmailAuth;