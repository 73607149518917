import React, { useEffect } from 'react';
import { Menu, MenuItemLink, Sidebar, useRefresh, useResourceDefinitions, useTranslate } from 'react-admin';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { Box } from '@mui/material';
import dataProvider from '../../dataProvider';
import { useDispatch, useSelector } from 'react-redux';
import { setBranches, setSelectedBranch } from '../../Store/branchReducer';
import { setDate, setMaxMount, setMinMount } from '../../Store/filterReducer';
import dayjs from 'dayjs';
import { setMerchants, setSelectedMerchant } from '../../Store/merchantReducer';

const params = {
  pagination: { perPage: 999, page: 1 },
  sort: { field: 'name', order: 'ASC' },
  filter: {}
};

const MenuCustom = () => {
  const { branches, selectedBranch } = useSelector(state => state.branch);
  const { merchants, selectedMerchant } = useSelector(state => state.merchant);
  const resourcesDefinitions = useResourceDefinitions();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);

  useEffect(() => {
    dataProvider.getList('merchants', params).then(({ data }) => {
      console.log('getMerchant menu custom');
      if (data.length > 0) {
        dispatch(setMerchants(data));
        dispatch(setSelectedMerchant(data[0]));
      }
    });
  }, []);

  useEffect(() => {
    {
      selectedMerchant && (
        dataProvider.getList('branches', { ...params, filter: { merchant: selectedMerchant } })
          .then(({ data }) => {
            if (data.length > 0) {
              localStorage.setItem('RaStore.branch', JSON.stringify(data[0]));
              dispatch(setBranches(data));
              dispatch(setSelectedBranch(data[0]));
            }
          })
      );
    }
  }, [selectedMerchant]);

  const onChangeValueBranch = (_, value) => {
    dispatch(setSelectedBranch(value));
    dispatch(setDate(dayjs()));
    dispatch(setMinMount(null));
    dispatch(setMaxMount(null));
    localStorage.setItem('RaStore.branch', JSON.stringify(value));
    refresh();
  };

  const onChangeValueMerchant = (_, value) => {
    dispatch(setSelectedMerchant(value));
    dispatch(setDate(dayjs()));
    dispatch(setMinMount(null));
    dispatch(setMaxMount(null));
    refresh();
  };

  return (
    <Sidebar
      open={true}
      sx={{
        '&.RaSidebar-appBarCollapsed': {
          marginTop: 6
        }
      }}
    >
      <Menu sidebarOpen={true}>
        {
          merchants.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '12px 3px 9px 3px',

                '& .MuiPopper-root div': {
                  maxWidth: '210px'
                }
              }}
            >
              <Autocomplete
                disablePortal
                disableClearable
                value={selectedMerchant}
                getOptionLabel={(option) => option}
                options={merchants}
                onChange={onChangeValueMerchant}
                sx={{ width: 210 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    label={translate('merchant')}
                    sx={{
                      backgroundColor: 'primary.main',
                      borderRadius: 1,
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#fff'
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                      },
                    }}
                  />
                )}
              />
            </Box>
          )
        }

        {
          branches.length > 0 && selectedMerchant && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 3px 12px 3px',

                '& .MuiPopper-root div': {
                  maxWidth: '210px'
                }
              }}
            >
              <Autocomplete
                disablePortal
                disableClearable
                value={selectedBranch}
                getOptionLabel={(option) => option.name}
                options={branches}
                onChange={onChangeValueBranch}
                sx={{ width: 210 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    label={translate('branch')}
                    sx={{
                      backgroundColor: 'primary.main',
                      borderRadius: 1,
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#fff'
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                      },
                    }}
                  />
                )}
              />
            </Box>
          )
        }
        <Box>
          {
            resources.map(resource => {
              if (resource.hasList && !resource.options?.menuParent) {
                return (
                  <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={(resource.options && translate(resource.options.label)) || translate(resource.name)}
                    leftIcon={resource.icon ? <resource.icon /> : null}
                    sx={{
                      color: '#fff',
                      padding: '3px 12px',
                      margin: '12px 18px 12px 0',
                      fontWeight: '600',
                      borderBottomRightRadius: '18px',
                      fontSize: '.9rem',
                      lineHeight: '1.75',
                      letterSpacing: '0.01em',
                      '& .MuiListItemIcon-root': {
                        fontSize: 21,
                        color: '#fff'
                      },
                      '&.RaMenuItemLink-active': {
                        backgroundColor: 'secondary.light',
                        color: '#fff',
                        '& .MuiListItemIcon-root': {
                          color: '#fff'
                        }
                      }
                    }}
                  />
                );
              }
            })
          }
        </Box>
      </Menu>
      <Box sx={{ position: 'absolute', bottom: 52, width: '90%', display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => window.open('https://bitcoincashargentina.com/', '_blank')}
        >
          <img src={require('../../assets/images/bchSupport.png')} style={{ width: '70px', objectFit: 'contain' }} />
          <Typography variant='subtitle2'>{translate('view_web')}</Typography>
        </Box>
      </Box>
    </Sidebar>
  );
};

export default MenuCustom;