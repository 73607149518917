import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { BiExit, BiUndo } from 'react-icons/bi';
import { useRecordContext, useRedirect, useTranslate } from 'react-admin';
import TooltipCustom from '../TooltipCustom/TooltipCustom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const ActionsListTransactions = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const translate = useTranslate();

  const handleClickLink = () => {
    window.open(`https://explorer.bitcoinunlimited.info/tx/${record.hash}`, '_blank');
  };

  const undoTransaction = () => {
    redirect(`/transactions/${record?.id}`);
  };

  const isRefund = record.refunded_amount > 0;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
      {/* <IconButton aria-label="copy" sx={{ padding: 1 }} onClick={copyClipHash}>
        <BiCopy size={15} color='#fff' />
      </IconButton> */}
      <TooltipCustom title={isRefund ? translate('view_detail') : translate('refund_pay')} >
        <IconButton aria-label="undo" sx={{ padding: 1 }} onClick={undoTransaction}>
          {
            isRefund ?
              <RemoveRedEyeIcon sx={{ color: '#fff', fontSize: 16 }} /> :
              <BiUndo size={15} color='#fff' />
          }
        </IconButton>
      </TooltipCustom>
      <Tooltip title={translate('activity_resource.view_in_explorer')}>
        <IconButton aria-label="delete" sx={{ padding: 1 }} onClick={handleClickLink}>
          <BiExit size={15} color='#fff' />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ActionsListTransactions;