import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box, CircularProgress } from '@mui/material';
import { formatNumber } from '../../helpers/multiuso';
import { COLORS } from '../../assets/constants/theme';

const styleChart = () => ({
  backgroundColor: [COLORS.light.primary.main, '#388a8e', COLORS.light.primary.light],
  borderWidth: 0,
  hoverBorderWidth: 0,
  hoverBorderDashOffset: 12,
  hoverOffset: 2
});

const ChartBar = ({ isLoading, data, getLabels, getData }) => {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    layout: {
      padding: 0,
    },
    elements: {
      padding: 5,
      margin: 10,
      point: {
        pointRadius: 6,
      }
    },
    scales: {
      x: {
        min: 0,
        max: 31,
        suggestedMin: 0,
        suggestedMax: 31,
        grid: {
          display: false
        },
      },
      y: {
        alignToPixels: true,
        min: 0,
        border: { dash: [8, 8] },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: 'gray',
          tickColor: 'transparent',
          tickLength: 15
        },
        ticks: {
          display: true,
          callback: function (value) {
            return formatNumber(value);
          }
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (value, data) {
            return 'Monto: $ ' + value.formattedValue;
          },
        }
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      {
        isLoading ? (
          <Box sx={{
            width: 660,
            height: 330,
            borderRadius: 2,
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            <CircularProgress color='secondary' />
          </Box>
        ) : (
          <Bar
            id='chartId'
            options={options}
            data={{
              labels: getLabels(data),
              datasets: [
                {
                  id: 1,
                  label: '',
                  data: getData(data),
                  fill: {
                    target: 'origin',
                    above: 'rgba(56, 142, 60, .3)',   // Area will be red above the origin
                  },
                  ...styleChart()
                },
              ],
            }}
          />
        )
      }
    </Box>
  );
};

export default ChartBar;