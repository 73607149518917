import React, { useEffect, useState } from 'react';
import ModalForm from '../../../Components/ModalForm/ModalForm';
import { ReferenceInput, required, useCreate, usePermissions, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { TextFieldCustom, TextInputCustom } from '../../../Components/InputsCustom/TextInputCustom/TextInputCustom';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import SelectInputCustom from '../../../Components/InputsCustom/SelectInputCustom';
import { RUBROS } from '../../../assets/constants/data';
import dataProvider from '../../../dataProvider';
import { setCategories } from '../../../Store/branchReducer';
import AutocompleteInputCustom from '../../../Components/InputsCustom/AutocompleteInputCustom/AutocompleteInputCustom';

const FormBranch = ({ title }) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const { categories } = useSelector(state => state.branch);
  const language = JSON.parse(localStorage.getItem('RaStore.locale'));
  const [allCategories, setAllCategories] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (categories.length > 0) {
      const languageAct = language == 'es' ? 'spa' : 'eng';
      const languageCategories = categories.map(c => ({ value: c.eng, text: c[languageAct] }));
      setAllCategories(languageCategories);
    } else {
      dataProvider.getCustom('branches/categories').then(r => {
        if (r.results.length > 0) {
          dispatch(setCategories(r.results));
        }
      });
    }
  }, [language, categories]);

  return (
    <ModalForm title={translate(title)} source='/branches'>
      <TextInputCustom size='small' source="name" label={'branch_resource.form.name_branch'} fullWidth validate={[required()]} />
      <TextInputCustom source="address" label={'branch_resource.form.branch_address'} fullWidth />
      <TextInputCustom source="description" label={'branch_resource.form.description'} fullWidth />
      <TextInputCustom source="bch_address" label={'branch_resource.form.bch_address'} fullWidth validate={[required()]} />

      {
        permissions == 'admin' && (
          <ReferenceInput source={'manager_id'} reference="users" filter={{ role: 'manager' }} perPage={999}>
            <AutocompleteInputCustom label={'Manager'} validate={[required()]} optionText={'email'} />
          </ReferenceInput>
        )
      }
      <SelectInputCustom source='category' label={translate('category')} choices={allCategories} optionText={'text'} optionValue={'value'} />
      <AutocompleteLocalComponent label={translate('merchant')} />
    </ModalForm>
  );
};

const AutocompleteLocalComponent = ({ label }) => {
  const { setValue, formState, ...rest } = useFormContext();
  const { merchants } = useSelector(state => state.merchant);
  const record = useRecordContext();
  const [valueMerch, setValueMerch] = useState(record?.merchant);

  const onChange = (_, newValue) => {
    setValue('merchant', newValue);
  };
  return (
    <Autocomplete
      value={valueMerch}
      freeSolo
      onChange={onChange}
      options={merchants}
      disableClearable
      renderInput={(params) => (
        <TextFieldCustom {...params}
          onBlur={({ target }) => setValue('merchant', target.value)}
          onChange={({ target }) => setValueMerch(target.value)}
          label={label} />
      )}
    />
  );
};

const CreateMerchant = ({ open, setOpen }) => {
  // const { onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();
  const [value, setValue] = React.useState('');
  const refresh = useRefresh();

  const handleSubmit = event => {
    event.preventDefault();
    create(
      'merchants',
      { data: { name: value } },
      {
        onSuccess: (data) => {
          setValue('');
          // onCreate(data);
          refresh();
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Typography variant='body1' sx={{ margin: '12px auto 0 auto', color: '#000' }}>Crear negocio</Typography>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextFieldCustom
            label="Nombre"
            value={value}
            onChange={event => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Crear</Button>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormBranch;