import { Admin, Resource } from 'react-admin';
import LoginPage from './Resources/Login/LoginPage';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import LayoutCustom from './Components/LayoutCustom/LayoutCustom';
import { darkTheme, lightTheme } from './Theme';
import Branches from './Resources/Branch';
import { QueryClient } from 'react-query';
import User from './Resources/User';
import Merchant from './Resources/Merchant';
import Transactions from './Resources/Transactions';
import React, { useEffect } from 'react';
import Dashboard from './Resources/Dashboard';
import { Provider } from 'react-redux';
import store from './Store/store';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import './style.css';
import { i18nProvider } from './Translations/i18nProvider';
import Login from './Resources/Login/Login';
import SupportBtn from './Components/SupportBtn/SupportBtn';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    },
  },
});

const App = () => {

  useEffect(() => {
    dayjs.locale('es');
  }, []);

  return (
    <Provider store={store}>
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        theme={lightTheme}
        darkTheme={darkTheme}
        layout={LayoutCustom}
        loginPage={Login}
        queryClient={queryClient}
        i18nProvider={i18nProvider}
        requireAuth
        disableTelemetry
        defaultTheme='light'
      >
        {permissions => (
          <>
            <Resource {...Dashboard} />
            <Resource {...Transactions} />
            <Resource {...Branches} />
            {
              // permissions !== 'cashier' && permissions !== 'manager' && <Resource {...Merchant} />
            }
            {
              permissions !== 'cashier' && <Resource {...User} />
            }
            <Resource name='merchants' />
          </>
        )}
      </Admin>
      <SupportBtn />
    </Provider>
  );
};

export default App;