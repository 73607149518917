import React from 'react';
import { EditBase, useRedirect } from 'react-admin';
import FormBranch from './FormBranch';
import { useDispatch } from 'react-redux';
import dataProvider from '../../../dataProvider';
import { setBranches } from '../../../Store/branchReducer';

const params = {
  pagination: { perPage: 999, page: 1 },
  sort: { field: 'name', order: 'ASC' },
  filter: {}
};

const EditBranch = () => {
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const onSuccess = () => {
    dataProvider.getCustom('branches')
      .then(({ results }) => {
        if (results.length > 0) {
          dispatch(setBranches(results));
        }
      });

    redirect('/branches');
  };

  return (
    <EditBase mutationMode='pessimistic' redirect='list' mutationOptions={{ onSuccess }}>
      <FormBranch title={'branch_resource.edit.title'} />
    </EditBase>
  );
};

export default EditBranch;