import React, { useEffect, useState } from 'react';
import CardDefault from '../CardDefault/CardDefault';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { formatMount } from '../../helpers/multiuso';
import { useTranslate } from 'react-admin';

const _getKPIs = (data, currency) => {
  if (data) {
    return data.reduce((acc, current) => ({
      transactions: acc.transactions += current.transactions,
      monto: acc.monto += currency == 'ARS' ? current.ars : current.usd,
      devoluciones: acc.devoluciones += currency == 'ARS' ? current.refunded_ars : current.refunded_usd,
    }), { transactions: 0, monto: 0, devoluciones: 0 });
  }
  return [];
};

const AditionalData = ({ data, isLoading }) => {
  const { currency } = useSelector(state => state.config);
  const [kpi, setKpi] = useState(null);
  const translate = useTranslate();

  useEffect(() => {
    if (!isLoading) {
      setKpi(_getKPIs(data && data[0].data, currency));
    }
  }, [currency, isLoading]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 1 }}>

      <CardTitleData title={translate('transactions')} data={kpi?.transactions} />

      <CardTitleData
        title={translate('mount')}
        data={parseInt(kpi?.monto) ? `$ ${formatMount(kpi.monto, currency)}` : '$ 0'}
      />

      <CardTitleData
        title={translate('refundeds')}
        data={kpi?.devoluciones ? `$ ${formatMount(kpi.devoluciones, currency)}` : '$ 0'}
      />

    </Box>
  );
};

const CardTitleData = ({ title, data }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant='subtitle1' >{title}</Typography>
      <CardDefault style={{ minWidth: 90, justifyContent: 'center' }}>
        <Typography variant='body1' sx={{ color: '#fff' }}>{data ? data : '0'}</Typography>
      </CardDefault>
    </Box>
  );
};

export default AditionalData;