import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';
import { BiSolidBarChartAlt2 } from 'react-icons/bi';
import { TbArrowsExchange2 } from 'react-icons/tb';
import { MdOutlineStore } from 'react-icons/md';
import { TbUsers } from 'react-icons/tb';

const MenuLogin = () => {
  const translate = useTranslate();

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '75px'
        }}
      >
        <Autocomplete
          disablePortal
          disableClearable
          getOptionLabel={(option) => option}
          readOnly
          sx={{ width: 210 }}
          renderInput={(params) => (
            <TextField
              {...params}
              size='small'
              label={translate('merchant')}
              sx={{
                backgroundColor: 'primary.main',
                borderRadius: 1,
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#fff'
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                },
              }}
            />
          )}
        />
        <Autocomplete
          disablePortal
          disableClearable
          getOptionLabel={(option) => option}
          readOnly
          sx={{ width: 210, marginTop: '11px' }}
          renderInput={(params) => (
            <TextField
              {...params}
              size='small'
              label={translate('branch')}
              sx={{
                backgroundColor: 'primary.main',
                borderRadius: 1,
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#fff'
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                },
              }}
            />
          )}
        />
      </Box>

      <Box sx={{ width: '100%', marginTop: '18px' }}>
        <MenuItemResource title={'Dashboard'} icon={<BiSolidBarChartAlt2 style={{ fontSize: 21 }} />} />
        <MenuItemResource title={translate('Actividad')} icon={<TbArrowsExchange2 style={{ fontSize: 21 }} />} />
        <MenuItemResource title={translate('Sucursales')} icon={<MdOutlineStore style={{ fontSize: 21 }} />} />
        <MenuItemResource title={translate('Usuarios')} icon={<TbUsers style={{ fontSize: 21 }} />} />
      </Box>

      <Box sx={{ position: 'absolute', bottom: 52, width: '90%', display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => window.open('https://bitcoincashargentina.com/', '_blank')}
        >
          <img src={require('../../assets/images/bchSupport.png')} style={{ width: '70px', objectFit: 'contain' }} />
          <Typography variant='subtitle2'>{translate('view_web')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const MenuItemResource = ({ title, icon }) => {
  return (
    <Button
      variant="text"
      startIcon={icon}
      size='small'
      sx={styles.menuItem}
    >
      <Typography sx={styles.typography}>{title}</Typography>
    </Button>
  );
};


const styles = {
  container: {
    width: 240,
    backgroundColor: 'primary.light',
    height: '100vh',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0
  },
  menuItem: {
    width: '92%',
    color: '#fff',
    padding: '4px 15px',
    margin: '5px 16px 5px 0',
    borderBottomRightRadius: '18px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    gap: '12px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.05)'
    }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    fontSize: '.9rem',
    lineHeight: '1.75',
    letterSpacing: '0.01em',
    '-webkit-font-smoothing': 'antialiased',
    textSizeAdjust: '100%',
    colorScheme: 'light'
  }
};

export default MenuLogin;