import { TbArrowsExchange2 } from 'react-icons/tb';
import ListTransactions from './ListTransactions';
import EditTransaction from './Form/EditTransaction';

export default {
  name: 'transactions',
  icon: TbArrowsExchange2,
  list: ListTransactions,
  edit: EditTransaction,
  options: { label: 'Actividad' }
};