import React from 'react';
import { NumberInput } from 'react-admin';

const NumberInputCustom = ({ ...rest }) => {
  return (
    <NumberInput
      sx={{
        color: '#000',
        '& .Mui-disabled': {
          color: 'gray',
          textFillColor: 'gray'
        },
        '& label.Mui-focused': {
          color: '#000',
        },
        '& label.Mui-disabled': {
          color: 'gray'
        },
        '& label': {
          color: 'gray',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'gray',
        },
        '& .MuiOutlinedInput-root': {
          color: '#000',
          '& fieldset': {
            borderRadius: 2,
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: 'gray',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray'
          },
          '&.Mui-focused fieldset': {
            borderColor: 'secondary.main',
          },
        },
      }}
      {...rest}
    />
  );
};

export default NumberInputCustom;