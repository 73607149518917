import { Button } from '@mui/material';
import React from 'react';
import { COLORS } from '../../assets/constants/theme';

const ButtonFilter = ({ onClick, title, active, ...rest }) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      color='secondary'
      size='small'
      sx={{
        border: active ? `1px solid ${COLORS.light.secondary.main}` : '1px solid gray',
        minWidth: '96px',
        padding: '0px 12px',
        borderRadius: 6,
        color: active ? 'text.primary' : 'gray',
        fontSize: '11px',
        backgroundColor: active && 'primary.main',
        '&:hover': {
          backgroundColor: 'primary.light',
          color: 'text.primary'
        }
      }}
      {...rest}
    >
      {title}
    </Button>
  );
};

export default ButtonFilter;