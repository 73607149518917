import React from 'react';
import CardDefault from '../../CardDefault/CardDefault';
import { Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import useCopyClipboard from '../../../Hooks/useCopyClipboard';

const TextField = ({ variant = 'primary', small, formatRecord, source, fontSize = '16px', label, enableCopy = false, style }) => {
  const recordCtx = useRecordContext();
  const copyClip = useCopyClipboard();

  return (
    <CardDefault
      variant={variant}
      small={small}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: enableCopy ? 'pointer' : 'default',
        ...style
      }}
      onClick={() => enableCopy && copyClip(recordCtx[source])}
    >
      <Typography
        variant='subtitle2'
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          fontSize: fontSize
        }}
        label={label}
      >
        {
          formatRecord ? formatRecord(recordCtx) : recordCtx[source]
        }
      </Typography>
    </CardDefault>
  );
};

export default TextField;