import { Box } from '@mui/material';
import React from 'react';
import { Datagrid } from 'react-admin';
import CustomEmpty from '../CustomEmpty/CustomEmpty';

const DatagridCustom = ({ children, maxWidth = 1200, size = 'medium', noPadding, ...rest }) => {



  return (
    <Datagrid
      hover={false}
      bulkActionButtons={false}
      size={size}
      empty={<CustomEmpty title={'empty_branchs'} />}
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',

        '& .RaDatagrid-row': {
          backgroundColor: 'transparent',
        },
        '& .RaDatagrid-rowCell': {
          height: size == 'medium' ? '90px' : '30px',
          borderBottom: 'none',
          padding: noPadding ? '6px 0' : '6px 16px'
        },
        '& .RaDatagrid-tableWrapper': {
          maxWidth: maxWidth,
        },
        '& .RaDatagrid-headerCell': {
          textAlign: 'center',
          borderBottom: 'none',
          backgroundColor: 'transparent',
          color: 'inherit'
        }
      }}
      {...rest}
    >
      {children}
    </Datagrid>
  );
};

export default DatagridCustom;