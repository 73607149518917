
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currency: 'ARS',
  language: 'spanish'
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setlanguage: (state, action) => {
      state.language =  action.payload;
    },
  },
});

export const { setCurrency, setlanguage } = configSlice.actions;

export default configSlice.reducer;