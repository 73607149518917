import en from 'ra-language-english';

export const englishMessages = {
  ...en,
  Cashier: 'Cashier',
  Cajero: 'Cashier',
  cajero: 'Cashier',
  cashier: 'Cashier',
  category: 'Category',
  Dashboard: 'Dashboard',
  Sucursales: 'Branches',
  Actividad: 'Activity',
  Usuarios: 'Users',
  name: 'Name',
  merchant: 'Merchant',
  branch: 'Branch',
  save: 'Save',
  note: 'Notes',
  time: 'Time',
  activity: 'Activity',
  mount: 'Amount',
  Todos: 'All',
  view_detail: 'View detail',
  refund_pay: 'Refund Payment',
  today: 'today',
  weekly: 'weekly',
  monthly: 'monthly',
  annual: 'annual',
  empty_branchs: 'No branches to show',
  personalized: 'personalized',
  refundeds: 'Refunds',
  transactions: 'Transactions',
  no_selected_merchant_branch: 'Please select a merchant and branch to view their transactions',
  no_selected_merchant_branch_in_dashboard: 'Select a merchant and a branch to view the dashboard',
  branch_selected: 'Branch %{name}',
  all_transactions: 'All transactions',
  all_refunds: 'All refunds',
  exclued_total_refunds: 'Exclude total refunds',
  empty_transactions: 'There are no transactions to show for this day',
  mount_refund_error: 'The amount to be returned cannot be less than 1',
  view_web: 'Visit website',
  create_user: 'Create User',
  sign_in_other_email: 'Sign in',
  user_created_success: 'User created successfully.',
  user_created_error: 'Error creating the user, please try again later.',
  sign_in: 'Sign in',
  new_user: 'Don\'t have an account?',
  has_account: 'Do you already have an account?',
  email_send_success: 'We have sent a verification email to your email address.',
  email_not_found: 'Email address not found.',
  all: 'All',
  dashboard: {
    view_branch: 'Branch',
    view_merchant: 'Merchant',
    view_all: 'All'
  },
  delete: {
    title: ' %{title}: "%{name}"',
    subtitle: 'Are you sure? This %{title} will be completely deleted.'
  },
  activity_resource: {
    pay_id: 'Payment id',
    view_in_explorer: 'View in explorer',
    refund: {
      title: 'Refund Payment',
      pay_id: 'Payment id',
      sell_mount: 'Sale Amount',
      refund_mount: 'Refund Amount'
    }
  },
  branch_resource: {
    title: 'Manage Branches',
    edit: {
      title: 'Edit Branch'
    },
    create: {
      title: 'Create New Branch',
      button: 'Create Branch',
    },
    field: {
      bch_address: 'BCH Address',
      branch: 'Branch'
    },
    form: {
      name_branch: 'Branch Name',
      branch_address: 'Branch Address',
      description: 'Description',
      bch_address: 'BCH Address'
    }
  },
  user_resource:{
    title: {
      list: 'Manage Users',
      singular: 'User',
      plural: 'Users'
    },
    create: {
      button:  'Create User',
      title: 'Create User'
    },
    edit: {
      title: 'Edit User'
    }
  }
};