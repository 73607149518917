import es from '@blackbox-vision/ra-language-spanish';

export const spanishMessages = {
  ...es,
  Dashboard: 'Métricas',
  category: 'Rubro',
  Sucursales: 'Sucursales',
  Actividad: 'Actividad',
  Usuarios: 'Usuarios',
  name: 'Nombre',
  merchant: 'Negocio',
  branch: 'Sucursal',
  save: 'Guardar',
  note: 'Notas',
  time: 'Hora',
  activity: 'Actividad',
  mount: 'Monto',
  view_detail: 'Ver detalle',
  refund_pay: 'Devolver Pago',
  today: 'hoy',
  weekly: 'semanal',
  monthly: 'mensual',
  annual: 'anual',
  empty_branchs: 'No hay sucursales para mostrar',
  personalized: 'personalizado',
  refundeds: 'Devoluciones',
  transactions: 'Transacciones',
  no_selected_merchant_branch: 'Por favor selecciona un negocio y sucursal para ver sus transacciones',
  no_selected_merchant_branch_in_dashboard: 'Por favor selecciona un negocio y sucursal para ver sus métricas',
  branch_selected: 'Sucursal %{name}',
  all_transactions: 'Todas las transacciones',
  all_refunds: 'Todas las devoluciones',
  exclued_total_refunds: 'Excluir Devoluciones totales',
  empty_transactions: 'No hay Transacciones para mostrar en este dia',
  view_web: 'Visitar sitio web',
  mount_refund_error: 'El monto a devolver no puede ser menor a 1',
  create_user: 'Crear Usuario',
  sign_in_other_email: 'Ingresar con otro email',
  user_created_success: 'Usuario creado con éxito.',
  user_created_error: 'Error al crear el usuario, por favor intente mas tarde.',
  sign_in: 'Ingresar',
  new_user: '¿Eres Nuevo?',
  has_account: '¿Ya tienes cuenta?',
  email_send_success: 'Hemos enviado un mail de verificación a tu dirección de correo electrónico.',
  email_not_found: 'No se encuentra la dirección de correo electrónico.',
  all: 'Todos',
  dashboard: {
    view_branch: 'Sucursal',
    view_merchant: 'Negocio',
    view_all: 'Todos'
  },
  delete: {
    title: 'Eliminar %{title}: "%{name}"',
    subtitle: '¿Estas seguro? Se eliminará por completo este %{title}.'
  },
  activity_resource: {
    pay_id: 'Identificador de pagos',
    view_in_explorer: 'Ver en explorador',
    refund: {
      title: 'Devolver Pago',
      pay_id: 'ID de pago',
      sell_mount: 'Monto de Venta',
      refund_mount: 'Monto a devolver'
    }
  },
  branch_resource: {
    title: 'Administrar Sucursales',
    edit: {
      title: 'Editar Sucursal'
    },
    create: {
      title: 'Crear Nueva Sucursal',
      button: 'Crear Sucursal',
    },
    field: {
      bch_address: 'Dirección BCH',
      branch: 'Sucursal'
    },
    form: {
      name_branch: 'Nombre Sucursal',
      branch_address: 'Dirección Sucursal',
      description: 'Descripción',
      bch_address: 'Dirección BCH'
    }
  },
  user_resource:{
    title: {
      list: 'Administrar Usuarios',
      singular: 'Usuario',
      plural: 'Usuarios'
    },
    create: {
      button:  'Crear Usuario',
      title: 'Crear Usuario'
    },
    edit: {
      title: 'Editar Usuario'
    }

  }
};
