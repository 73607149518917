import { Box } from '@mui/material';
import React from 'react';
import { Pagination, PaginationActions } from 'react-admin';

const PaginationCustom = () => {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
      <Box sx={{ width: 600 }}>
        <Pagination
          rowsPerPageOptions={[]}
          ActionsComponent={ActionsComponent}
          sx={{
            color: '#000',
            '& .MuiPaginationItem-root': {
              color: 'gray'
            }
          }}
        />
      </Box>
    </Box>
  );
};

const ActionsComponent = props => (
  <PaginationActions
    {...props}
    // these props are passed down to the Material UI <Pagination> component
    color="secondary"
    showFirstButton
    showLastButton
  />
);

export default PaginationCustom;