import React, { useEffect, useState } from 'react';
import { ListBase, usePermissions, useTranslate } from 'react-admin';
import DashboardCustom from '../../Components/DashboardCustom/DashboardCustom';
import { useDispatch, useSelector } from 'react-redux';
import FiltersDashboard from './Filters/FiltersDashboard';
import { Box, MenuItem, ToggleButtonGroup, Typography } from '@mui/material';
import dayjs from 'dayjs';
import WrapperComponent from '../../Components/WrapperComponent/WrapperComponent';
import CustomToggleButton from '../../Components/CustomToggleButton/CustomToggleButton';
import SelectMui from '../../Components/InputsCustom/SelectMui/SelectMui';
import dataProvider from '../../dataProvider';
import { setCategories } from '../../Store/branchReducer';

const ListDashboard = () => {
  const { selectedBranch } = useSelector(state => state.branch);
  const [filters, setFilters] = useState({ type: 'today', startDate: dayjs().toDate(), endDate: dayjs().toDate() });
  const { type, startDate, endDate, merchant, branch_id, category } = filters;
  const dispatch = useDispatch();
  const { permissions } = usePermissions();
  const translate = useTranslate();

  useEffect(() => {
    dataProvider.getCustom('branches/categories').then(r => {
      if (r.results.length > 0) {
        dispatch(setCategories(r.results));
      }
    });
  }, []);

  return (
    <WrapperComponent>
      {
        selectedBranch?.id ? (
          <ListBase
            filter={{ type, from_date: startDate, to_date: endDate, branch_id: branch_id?.id, merchant, category }}
            title={'Dashboard'}
          >
            <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '30px'
            }}>

              {
                permissions !== 'cashier' && (
                  <FilterToggleTop filters={filters} setFilters={setFilters} />
                )
              }

              <FiltersDashboard filters={filters} setFilters={setFilters} />

              <DashboardCustom filters={filters} />

            </Box>
          </ListBase>
        ) : (
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography>{translate('no_selected_merchant_branch_in_dashboard')}</Typography>
          </Box>
        )
      }
    </WrapperComponent>
  );
};

const FilterToggleTop = ({ filters, setFilters }) => {
  const [toggle, setToggle] = useState('all');
  const [rubro, setRubro] = useState('all');
  const { selectedBranch, categories } = useSelector(state => state.branch);
  const { selectedMerchant } = useSelector(state => state.merchant);
  const language = JSON.parse(localStorage.getItem('RaStore.locale'));
  const [allCategories, setAllCategories] = useState([]);
  const translate = useTranslate();

  useEffect(() => {
    if (categories.length > 0) {
      const languageAct = language == 'es' ? 'spa' : 'eng';
      const languageCategories = categories.map(c => ({ value: c.eng, text: c[languageAct] }));
      setAllCategories(languageCategories);
    }
  }, [categories, language]);

  useEffect(() => {
    const val = toggle;
    if (val === 'branch') {
      setFilters(prev => ({ ...prev, merchant: null, branch_id: selectedBranch }));
    } else if (val === 'merchant') {
      setFilters(prev => ({ ...prev, merchant: selectedMerchant, branch_id: null }));
    } else {
      setFilters(prev => ({ ...prev, merchant: null, branch_id: null }));
    }
  }, [selectedMerchant, selectedBranch]);

  const handleChange = (_, val) => {
    const { type, startDate, endDate } = filters;
    if (val) {
      setToggle(val);
      if (val === 'branch') {
        setFilters(prev => ({ ...prev, type, startDate, endDate, merchant: null, branch_id: selectedBranch }));
      } else if (val === 'merchant') {
        setFilters(prev => ({ ...prev, type, startDate, endDate, merchant: selectedMerchant, branch_id: null }));
      } else {
        setFilters(prev => ({ ...prev, type, startDate, endDate, merchant: null, branch_id: null }));
      }
    }
  };

  const onChangeCategory = ({ target }) => {
    const value = target.value;
    setRubro(value);
    setFilters(prev => ({ ...prev, category: value == 'all' ? null : value }));
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, gap: 6 }}>
      {/* <Typography variant='subtitle1'>Ver por</Typography> */}
      <ToggleButtonGroup
        value={toggle}
        exclusive
        onChange={handleChange}
      >
        <CustomToggleButton value="all">
          <Typography variant='subtitle2' sx={{ fontSize: 11, fontWeight: 600 }}>{translate('dashboard.view_all')}</Typography>
        </CustomToggleButton>
        <CustomToggleButton value="branch">
          <Typography variant='subtitle2' sx={{ fontSize: 11, fontWeight: 600 }}>{translate('dashboard.view_branch')}</Typography>
        </CustomToggleButton>
        <CustomToggleButton value="merchant">
          <Typography variant='subtitle2' sx={{ fontSize: 11, fontWeight: 600 }}>{translate('dashboard.view_merchant')}</Typography>
        </CustomToggleButton>
      </ToggleButtonGroup>

      <SelectMui
        variant='outlined'
        onChange={onChangeCategory}
        value={rubro}
      >
        <MenuItem value={'all'}>{translate('Todos')}</MenuItem>
        {
          allCategories.map((item) => (
            <MenuItem value={item.value}>{item.text}</MenuItem>
          ))
        }
      </SelectMui>
    </Box>
  );
};

export default ListDashboard;