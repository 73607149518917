import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';

const TooltipCustom = ({ children, ...rest }) => {
  return (
    <Tooltip
      {...rest}
      color={'#fff'}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipCustom;