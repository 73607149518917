import React from 'react';
import { AppBar, useRedirect } from 'react-admin';
import { Box, ToggleButtonGroup, Typography } from '@mui/material';
import imageLogo from '../../assets/images/isologo.png';
import UserMenuCustom from './UserMenuCustom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrency } from '../../Store/configReducer';
import CustomToggleButton from '../CustomToggleButton/CustomToggleButton';

const AppbarCustom = (props) => {
  const { currency } = useSelector(state => state.config);
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const handleClick = () => {
    redirect('transactions');
  };

  const handleCurrency = (_, currency) => {
    if (currency) {
      dispatch(setCurrency(currency));
    }
  };

  return (
    <AppBar
      {...props}
      hidden={false}
      color='primary'
      alwaysOn
      userMenu={<UserMenuCustom />}
      sx={{
        padding: '6px 0',
        boxShadow: 'none',
      }}
    >
      <Box flex={'1'} sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 2, cursor: 'pointer' }}
          onClick={handleClick}
        >
          <img src={imageLogo} style={{ width: 36, height: 36 }} />
          <Typography variant="subtitle2" id="react-admin-title">Bitcoin Cash Argentina</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 3 }}>
          {/* <SwitchCustomCurrency /> */}
          <ToggleButtonGroup
            value={currency}
            exclusive
            onChange={handleCurrency}
            aria-label="text alignment"
          >
            <CustomToggleButton value="ARS" aria-label="ARS" >
              <Typography sx={{ fontSize: 12, fontWeight: 600, color: '#fff' }}>ARS</Typography>
            </CustomToggleButton>
            <CustomToggleButton value="USD" aria-label="USD">
              <Typography sx={{ fontSize: 12, fontWeight: 600, color: '#fff' }}>USD</Typography>
            </CustomToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </AppBar>
  );
};

export default AppbarCustom;