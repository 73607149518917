import { Box } from '@mui/material';
import React from 'react';
import { DeleteWithConfirmButton, EditButton, useRecordContext, useTranslate } from 'react-admin';

const ActionsField = ({ title = 'registro', resource, deleteMutationOptions, hasDelete = true }) => {
  const recordCtx = useRecordContext();
  const translate = useTranslate();

  return (
    <Box
      sx={{
        width: '69px',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >

      <EditButton
        resource={resource}
        label={''}
        color={'secondary'}
        alignIcon='left'
        sx={{
          minWidth: 24,
          '& .MuiButton-startIcon': {
            marginRight: 0
          }
        }}
      />
      {
        hasDelete && (
          <DeleteWithConfirmButton
            confirmTitle={translate('delete.title', { title, name: recordCtx.name })}
            confirmContent={translate('delete.subtitle', { title })}
            translateOptions={{ name: recordCtx.name }}
            label=''
            color='error'
            mutationOptions={deleteMutationOptions}
            sx={{
              minWidth: 24,
              '& .MuiButton-startIcon': {
                marginRight: 0
              }
            }}
          />
        )
      }
    </Box>
  );
};

export default ActionsField;