import { Box } from '@mui/material';
import React from 'react';
import { SaveButton, useTheme } from 'react-admin';
import { COLORS } from '../../assets/constants/theme';

const WrapperSimpleForm = ({ children }) => {
  return (
    <Box
      sx={{
        marginTop: '40px',
        marginBottom: '40px',
        width: '60vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 99
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: 3,
        }}
      >
        {children}
        <ToolbarSimpleForm />
      </Box>
    </Box>

  );
};

const ToolbarSimpleForm = () => {
  const [theme, setTheme] = useTheme();

  return (
    <Box sx={{ padding: '0px 15px', margin: '12px 0px' }}>
      <SaveButton
        redirect={'list'}
        label={'save'}
        sx={{ backgroundColor: COLORS[theme]?.secondary.main, width: '100%', borderRadius: '15px', padding: '3px 6px' }}
        alwaysEnable icon={false}
      />
    </Box>
  );
};

export default WrapperSimpleForm;