import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin';
import { englishMessages } from './englishMessages';
import { spanishMessages } from './spanishMessages';

const translations = { en: englishMessages, es: spanishMessages };

export const i18nProvider = polyglotI18nProvider(
  locale => translations[locale] ? translations[locale] : translations.es,
  resolveBrowserLocale(),
  [
    { locale: 'es', name: 'Español' },
    { locale: 'en', name: 'English' }
  ],
);