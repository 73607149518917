import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdAttachMoney } from 'react-icons/md';
import FilterInput from '../InputsCustom/FilterInput/FilterInput';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../Hooks/useDebounce';
import dayjs from 'dayjs';
import PickerDate from '../PickerDate/PickerDate';
import RefreshButton from '../RefreshButton/RefreshButton';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { setDate, setMaxMount, setMinMount, setRefunded } from '../../Store/filterReducer';
import CustomToggleButton from '../CustomToggleButton/CustomToggleButton';
import { useTranslate } from 'react-admin';
import SelectMui from '../InputsCustom/SelectMui/SelectMui';

const FiltersList = () => {
  const { selectedBranch } = useSelector(state => state.branch);
  const { date } = useSelector(state => state.filter);
  const [localFilter, setLocalFilter] = useState({});
  const dispatch = useDispatch();
  const translate = useTranslate();

  const theme = useTheme();
  const debounce = useDebounce(localFilter, 500);

  useEffect(() => {
    dispatch(setMinMount(localFilter.min_mount ?? null));
    dispatch(setMaxMount(localFilter.max_mount ?? null));
  }, [debounce]);

  const handleSelectDate = (date) => {
    dispatch(setDate(dayjs(date)));
  };

  const handleInputs = ({ target }) => {
    const val = parseInt(target.value.replace(/[.,]/g, ''));
    const name = target.name;
    setLocalFilter(prev => ({ ...prev, [name]: val ?? null }));
  };


  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{
        maxWidth: 800,
        width: '100%',
        padding: '21px 3px',
        marginBottom: 3,
        borderBottom: `2px solid ${theme.palette.mode == 'dark' ? 'gray' : theme.palette.primary.main}`,
      }}>
        <Box sx={{ display: 'flex', gap: 6, marginBottom: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h6' sx={{ fontWeight: 500 }}>{translate('branch_selected', { name: selectedBranch?.name })}</Typography>
            <Typography variant='subtitle1'>{dayjs(date).format('dddd DD MMMM')}</Typography>
          </Box>
          <Box>
            <RefreshButton />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          <FilterRefunds />

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
            <FilterInput
              name='min_mount'
              type='number'
              value={localFilter.min_mount}
              placeholder={'Min'}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdAttachMoney style={{ color: 'gray', zIndex: 9 }} />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputs}
              sx={{ width: '110px' }}
            />
            -
            <FilterInput
              name='max_mount'
              type='number'
              placeholder={'Max'}
              value={localFilter.max_mount}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdAttachMoney style={{ color: 'gray', zIndex: 9 }} />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputs}
              sx={{ width: '120px' }}
            />
          </Box>

          <PickerDate onChange={handleSelectDate} date={date} />
        </Box>
      </Box>
    </Box>
  );
};

const FilterRefunds = () => {//'all', 'fully', 'exclude_fully', 'partially'
  const dispatch = useDispatch();
  const { refunded } = useSelector(state => state.filter);
  const translate = useTranslate();

  const handleChange = ({ target }) => {
    const value = target.value;
    if (value == 'default') {
      dispatch(setRefunded(null));
    } else {
      dispatch(setRefunded(value));
    }
  };

  return (
    <FormControl>
      <SelectMui
        variant='outlined'
        value={refunded ?? 'default'}
        onChange={handleChange}
      >
        <MenuItem value={'default'}>{translate('all_transactions')}</MenuItem>
        <MenuItem value={'all'}>{translate('all_refunds')}</MenuItem>
        <MenuItem value={'exclude_fully'}>{translate('exclued_total_refunds')}</MenuItem>
      </SelectMui>
    </FormControl>
  );
};

export default FiltersList;