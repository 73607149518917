import React, { useEffect, useState } from 'react';
import ModalForm from '../../../Components/ModalForm/ModalForm';
import { ReferenceInput, required, usePermissions, useRecordContext, useTranslate } from 'react-admin';
import SelectInputCustom from '../../../Components/InputsCustom/SelectInputCustom';
import { TextInputCustom } from '../../../Components/InputsCustom/TextInputCustom/TextInputCustom';
import AutocompleteInputCustom from '../../../Components/InputsCustom/AutocompleteInputCustom/AutocompleteInputCustom';
import { useSelector } from 'react-redux';

const FormUser = ({ title, isEdit }) => {
  const record = useRecordContext();

  const [merchantsForm, setMerchantsForm] = useState(null);
  const [merchantSelected, setMerchantSelected] = useState(null);
  const [roleSelected, setRoleSelected] = useState(record?.role);
  const { merchants } = useSelector(state => state.merchant);

  const { permissions } = usePermissions();
  const translate = useTranslate();

  useEffect(() => {
    const merchForm = merchants?.map(m => ({ value: m, name: m }));
    setMerchantsForm(merchForm);
  }, [merchants]);

  const choices = permissions == 'admin' ? [
    { id: 'admin', name: 'Admin' },
    { id: 'manager', name: 'Manager' },
    { id: 'cashier', name: 'Cajero' },
  ] : [{ id: 'cashier', name: 'Cajero' }];

  const handleChangeMerchant = (d) => {
    setMerchantSelected(d);
  };

  const handleSelectRole = ({ target }) => {
    setMerchantSelected(null);
    setRoleSelected(target.value);
  };

  return (
    <ModalForm title={translate(title)} source='/users'>
      <TextInputCustom size='small' source="name" label={'name'} fullWidth validate={[required()]} />
      <TextInputCustom size='small' source="email" label={'Email'} fullWidth validate={[required()]} />
      <SelectInputCustom
        onChange={handleSelectRole}
        source={'role'}
        choices={choices}
        disabled={isEdit}
        validate={[required()]}
      />

      {
        (permissions == 'admin' && merchantsForm?.length > 0 && (roleSelected === 'cashier' || roleSelected === 'manager')) && (
          <AutocompleteInputCustom
            optionValue={'name'}
            optionText={'name'}
            source={'merchant_id'}
            label={'merchant'}
            variant='outlined'
            choices={merchantsForm}
            onChange={handleChangeMerchant}
            validate={[required()]}
          />
        )
      }

      {
        (roleSelected === 'cashier' || (isEdit && roleSelected === null)) && (
          <ReferenceInput source="branch_id" perPage={999} reference="branches" filter={{ merchant: merchantSelected }}>
            <AutocompleteInputCustom
              validate={[required()]}
              source={'branch_id'}
              label={'branch'}
              optionText={'name'}
              variant='outlined'
            />
          </ReferenceInput>
        )
      }
    </ModalForm>
  );
};

export default FormUser;