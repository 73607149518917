import React from 'react';
import TextField from '../TextField/TextField';
import { useRecordContext } from 'react-admin';

const TextFieldRefund = ({ ...rest }) => {
  const recordCtx = useRecordContext();

  const borderBottom = recordCtx.refunded_amount ? '3px solid #e6630b' : 'none';

  return (
    <TextField
      {...rest}
      style={{
        width: 'auto',
        margin: '0px 12px',
        padding: '0 33px',
        borderBottom: borderBottom,

      }}
    />
  );
};

export default TextFieldRefund;