import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { SimpleForm, useRedirect } from 'react-admin';
import { GrFormClose } from 'react-icons/gr';
import WrapperSimpleForm from './WrapperSimpleForm';

const ModalForm = ({ children, title, source, hasClose = true }) => {
  const redirect = useRedirect();

  return (
    <SimpleForm
      component={WrapperSimpleForm}
      toolbar={false}
      sx={{ width: '100%' }}
    >
      {
        hasClose && (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '0px' }}>
            <IconButton onClick={() => redirect(source)}>
              <GrFormClose />
            </IconButton>
          </Box>
        )
      }

      <Typography
        variant='subtitle2'
        component="h2"
        sx={{ width: '100%', fontSize: '16px', color: '#000', textAlign: 'center' }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          padding: '6px 18px',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 390
        }}
      >
        {children}
      </Box>
    </SimpleForm>
  );
};

export default ModalForm;