import { ToggleButton } from '@mui/material';

const CustomToggleButton = ({ children, ...rest }) => {
  return (
    <ToggleButton {...rest} sx={{
      padding: '5px 9px',
      '&:hover': {
        backgroundColor: '#546E7A'
      },
      '&.Mui-selected': {
        backgroundColor: '#388E3C',
        color: '#fff'
      },
      '&.Mui-selected:hover': {
        backgroundColor: '#388E3C'
      }
    }}
    >
      {children}
    </ToggleButton>
  );
};

export default CustomToggleButton;