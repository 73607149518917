import { Box, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext, useTheme } from 'react-admin';
import { COLORS } from '../../../assets/constants/theme';
import CardDefault from '../../CardDefault/CardDefault';

const DoubleRow = ({ primarySrc, secondarySrc, variant, width, label }) => {
  const recordCtx = useRecordContext();

  return (
    <CardDefault
      width={width}
      variant={variant}
      style={{
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        minWidth: 220,
      }}
    >
      <Typography variant='subtitle1' sx={{ fontWeight: '600' }} label={label}>{recordCtx[primarySrc]}</Typography>
      <Typography
        variant='subtitle2'
        sx={{
          textOverflow: 'ellipsis',
          color: 'grey'
        }}
      >
        {recordCtx[secondarySrc]}
      </Typography>
    </CardDefault>
  );
};

export default DoubleRow;