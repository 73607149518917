
import dayjs from 'dayjs';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  date: dayjs(),
  min_mount: null,
  max_mount: null,
  refunded: null
};

const configSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setMinMount: (state, action) => {
      state.min_mount =  action.payload;
    },
    setMaxMount: (state, action) => {
      state.max_mount =  action.payload;
    },
    setRefunded: (state, action) => {
      state.refunded =  action.payload;
    },
  },
});

export const { setDate, setMinMount, setMaxMount, setRefunded } = configSlice.actions;

export default configSlice.reducer;