import { TextField, useTheme } from '@mui/material';
import React from 'react';
import { TextInput } from 'react-admin';

const styleField = (theme) => ({
  color: '#000',
  '& .Mui-disabled': {
    color: 'gray',
    textFillColor: 'gray !important'
  },
  '& label.Mui-focused': {
    color: '#000',
  },
  '& label.Mui-disabled': {
    color: 'gray'
  },
  '& label': {
    color: 'gray',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'gray',
  },
  '& .MuiOutlinedInput-root': {
    color: '#000',
    '& fieldset': {
      borderRadius: 2,
      borderColor: 'gray',
    },
    '&:hover fieldset': {
      borderColor: 'gray',
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'gray'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main,
    },
  },
});

export const TextInputCustom = ({ style, sx, ...props }) => {
  const theme = useTheme();

  return (
    <TextInput
      {...props}
      size='small'
      sx={styleField(theme)}
    />
  );
};

export const TextFieldCustom = ({ ...props }) => {
  const theme = useTheme();

  return (
    <TextField
      {...props}
      color='primary'
      size='small'
      sx={styleField(theme)}
    />
  );
};
