import { Box, IconButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import imageLogo from '../../assets/images/isologo.png';
import CustomToggleButton from '../../Components/CustomToggleButton/CustomToggleButton';
import { LocalesMenuButton, RefreshIconButton, ToggleThemeButton, useTheme } from 'react-admin';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrency } from '../../Store/configReducer';

const AppBarLogin = () => {
  const { currency } = useSelector(state => state.config);
  const theme = useTheme()[0];
  const dispatch = useDispatch();

  const handleCurrency = (_, currency) => {
    if (currency) {
      dispatch(setCurrency(currency));
    }
  };

  return (
    <Box sx={{
      ...styles.container,
      backgroundColor: theme == 'dark' ? 'rgb(32, 32, 32)' : 'primary.main',
      backgroundImage: theme == 'dark' && 'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))'
    }} >
      <Box flex={'1'} sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 2, }}
        >
          <img src={imageLogo} style={{ width: 36, height: 36 }} />
          <Typography variant="subtitle2" id="react-admin-title" sx={{ color: '#fff' }}>Bitcoin Cash Argentina</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 3 }}>
          {/* <SwitchCustomCurrency /> */}
          <ToggleButtonGroup
            exclusive
            value={currency}
            onChange={handleCurrency}
            aria-label="text alignment"
          >
            <CustomToggleButton value="ARS" aria-label="ARS" >
              <Typography sx={{ fontSize: 12, fontWeight: 600, color: '#fff' }}>ARS</Typography>
            </CustomToggleButton>
            <CustomToggleButton value="USD" aria-label="USD">
              <Typography sx={{ fontSize: 12, fontWeight: 600, color: '#fff' }}>USD</Typography>
            </CustomToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <LocalesMenuButton sx={{ color: '#fff' }} />
      <ToggleThemeButton sx={{ color: '#fff' }} />
      <RefreshIconButton />
      <IconButton aria-label="delete" disabled color="primary">
        <AccountCircleIcon sx={{ color: '#fff' }} />
      </IconButton>
    </Box >
  );
};

const styles = {
  container: {
    width: '100vw',
    height: '64px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    left: 0,
    zIndex: 3,
    padding: '6px 8px'
  }
};

export default AppBarLogin;