import { Box, Typography } from '@mui/material';
import React from 'react';

const TitleResource = ({ title }) => {
  return (
    <Box>
      <Typography variant='h5' component='h2'>{title}</Typography>
    </Box>
  );
};

export default TitleResource;