import { Box } from '@mui/material';
import React from 'react';

const ErrorCustom = () => {
  return (
    <Box>
      <img src={'../../assets/images/73696-error.gif'} style={{ width: '400px', height: '400px', objectFit: 'contain' }} />
    </Box>
  );
};

export default ErrorCustom;