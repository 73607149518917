import { Box, Typography } from '@mui/material';
import React from 'react';

const WrapperComponent = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      {children}
      <Box sx={{ mt: 3, mb: 1 }}>
        <Typography variant='subtitle2' color={'gray'} textAlign={'center'}>Made in Argentina</Typography>
      </Box>
    </Box>
  );
};

export default WrapperComponent;