import React from 'react';
import { CreateBase, useRedirect, useRefresh } from 'react-admin';
import FormBranch from './FormBranch';
import dataProvider from '../../../dataProvider';
import { useDispatch } from 'react-redux';
import { setBranches } from '../../../Store/branchReducer';
import { setMerchants, setSelectedMerchant } from '../../../Store/merchantReducer';

const params = {
  pagination: { perPage: 999, page: 1 },
  sort: { field: 'name', order: 'ASC' },
  filter: {}
};

const CreateBranch = () => {
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const onSuccess = () => {
    dataProvider.getCustom('branches')
      .then(({ results }) => {
        if (results.length > 0) {
          dispatch(setBranches(results));
        }
      });

    dataProvider.getList('merchants', params).then(({ data }) => {
      if (data.length > 0) {
        dispatch(setMerchants(data));
        dispatch(setSelectedMerchant(data[0]));
      }
    });

    redirect('/branches');
  };

  return (
    <CreateBase redirect='list' mutationOptions={{ onSuccess }}>
      <FormBranch title={'branch_resource.create.title'} />
    </CreateBase>
  );
};

export default CreateBranch;