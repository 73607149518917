import { defaultTheme } from 'react-admin';
import { COLORS } from '../assets/constants/theme';


const theme = {
  typography: {
    ...defaultTheme.typography,
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.light.primary.main
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: '#fff',
          backgroundColor: 'primary',
          transform: 'translateY(-5px) !important'
        },
        popper: {
          backgroundColor: 'transparent',
        }
      }
    },
    RaLogout: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    RaAppBar: { 
      styleOverrides: {
        root: {
          '& .RaAppBar-menuButton': {
            display: 'none'
          }
        }
      }
    }
  }
};

export const lightTheme = {
  ...defaultTheme,
  palette:{
    mode: 'light',
    ...COLORS.light
  },
  ...theme
};

export const darkTheme = {
  ...defaultTheme,
  palette:{
    mode: 'dark',
    ...COLORS.dark
  },
  ...theme
};