import { Box } from '@mui/material';
import React from 'react';
import MenuLogin from './MenuLogin';
import AppBarLogin from './AppBarLogin';
import { useTheme } from 'react-admin';
import ModalLoginCreateUser from './ModalLoginCreateUser';

const Login = () => {
  const theme = useTheme()[0];
  return (
    <Box sx={{ width: '100vw', height: '100vh', backgroundColor: theme == 'dark' ? '#202020' : '#ECEFF1' }}>
      <AppBarLogin />
      <MenuLogin />
      <ModalLoginCreateUser />
    </Box>
  );
};

export default Login;