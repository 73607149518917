import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/esm/locale';

const ModalDateRangePicker = ({ open, setOpen, ranges, handleSelect, disableRange, ...rest }) => {
  const [dateSelected, setDateSelected] = useState({ startDate: new Date(), endDate: new Date() });

  const onChangePicker = ({ date }) => {
    if (disableRange) {
      setDateSelected(prev => ({ ...prev, startDate: date.startDate, endDate: date.startDate }));
    } else {
      setDateSelected(prev => ({ ...prev, startDate: date.startDate, endDate: date.endDate }));
    }
  };

  const onClickHandler = () => {
    handleSelect(dateSelected);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
    >
      <Box
        sx={{
          '.rdrCalendarWrapper': {
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px'
          },
          '.rdrDefinedRangesWrapper': {
            display: 'none'
          },
          zIndex: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <DateRangePicker
          showMonthArrow={true}
          showMonthAndYearPickers={false}
          preventSnapRefocus={true}
          editableDateInputs={false}
          locale={es}
          showDateDisplay={false}
          ranges={[{ startDate: dateSelected.startDate, endDate: dateSelected.endDate, key: 'date' }]}
          onChange={onChangePicker}
          style={{ borderRadius: '12px' }}
          {...rest}
        />
        <Box sx={{
          width: '100%',
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: 1,
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px'
        }}
        >
          <Button variant='contained' color='secondary' size='small' onClick={onClickHandler}>Filtrar</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalDateRangePicker;