export const COLORS = {
  light: {
    primary: {
      main: '#37474F',
      light: '#546E7A',
      contrastText: '#fff'
    },
    secondary: {
      main: '#388E3C',
      light: '#4CAF50',
    },
    tertiary: {
      main: '#1976D2',
      light: '#2196F3',
    },
    text: {
      primary: '#e2e2e2',
      secondary: '#fff',
      contrastText: '#656665'
    },
    contrast: {
      main: '#000'
    },
    background: {
      paper: '#ECEFF1',
      default: '#F5F5F5'
    }
  },
  dark: {
    primary: {
      main: '#1d3744',
      light: '#41535d',
      contrastText: '#fff'
    },
    secondary: {
      main: '#3acb87',
      light: '#388e71'
    },
    tertiary: {
      main: '#3acb87',
      light: '#388e71'
    },
    text: {
      primary: '#fff',
      secondary: '#bfbebb',
      contrastText: '#fff'
    },
    contrast: {
      main: '#fff'
    },
    background: {
      paper: '#202020',
      default: '#202020'
    }
  }
};