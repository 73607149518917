import { useTheme } from '@mui/material';
import React from 'react';
import { SelectInput } from 'react-admin';

const SelectInputCustom = ({ sx, style, ...props }) => {
  const theme = useTheme();

  return (
    <SelectInput
      {...props}
      color='primary'
      size='small'
      sx={{
        color: '#000',
        '& label.Mui-focused': {
          color: '#000',
        },
        '& label': {
          color: 'gray',
        },
        '.Mui-disabled': {
          borderColor: 'gray',
          color: 'gray'
        },
        '& .Mui-disabled fieldset': {
          borderRadius: 2,
          borderColor: 'gray',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'gray',
        },
        '& .MuiSelect-icon': {
          color: 'gray',
        },
        '& .MuiInputLabel-root.Mui-disabled': {
          color: 'gray'
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0,0,0,.2)',
          color: 'gray'
        },
        '& .MuiOutlinedInput-input.Mui-disabled': {
          '-webkit-text-fill-color': 'gray'
        },
        '& .MuiOutlinedInput-root': {
          color: '#000',
          '& fieldset': {
            borderRadius: 2,
            borderColor: 'gray',
          },
          '& .Mui-disabled fieldset': {
            borderRadius: 2,
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: 'gray',
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.secondary.main,
          },
        },
      }}
    />
  );
};

export default SelectInputCustom;