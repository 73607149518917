import { configureStore } from '@reduxjs/toolkit';
import branchReducer from './branchReducer';
import configReducer from './configReducer';
import filterReducer from './filterReducer';
import merchantReducer from './merchantReducer';

const store = configureStore({
  reducer: {
    merchant: merchantReducer,
    branch: branchReducer,
    config: configReducer,
    filter: filterReducer
  },
});

export default store;