import React from 'react';
import { useListContext } from 'react-admin';
import { Box } from '@mui/material';
import AditionalData from './AditionalData';
import dayjs from 'dayjs';
import ChartBar from '../Charts/ChartBar';
import { useSelector } from 'react-redux';

const getLabels = (data) => {
  if (!data || data.length == 0) return [];

  if (data[1] && dayjs(data[0].date).isSame(data[1]?.date, 'day')) {
    return data.map(d => dayjs(d.date).format('HH'));
  } else {
    if (data.length > 8) {
      return data.map(d => dayjs(d.date).format('D MMM'));
    } else {
      return data.map(d => dayjs(d.date).format('ddd D'));
    }
  }
};

const getData = (data, currency) => {
  if (data) {
    if (currency == 'ARS') {
      return data.map(d => Math.floor(d.ars));
    } else {
      return data.map(d => Math.floor(d.usd));
    }
  }
  return [];
};

const DashboardCustom = ({ filters }) => {
  const { isLoading, isFetching, data } = useListContext();
  const { currency } = useSelector(state => state.config);

  return (
    <Box sx={{ width: 750, marginTop: 3, height: '100%' }}>
      <Box sx={{ height: 360 }}>
        <ChartBar isLoading={isLoading || isFetching} data={data && data[0]?.data} getLabels={getLabels} getData={(d) => getData(d, currency)} />
      </Box>
      <AditionalData filters={filters} data={data} isLoading={isLoading || isFetching} />
    </Box>
  );
};

export default DashboardCustom;