import { TbUsers } from 'react-icons/tb';
import ListUser from './ListUser';
import CreateUser from './Form/CreateUser';
import EditUser from './Form/EditUser';

export default {
  name: 'users',
  icon: TbUsers,
  list: ListUser,
  create: CreateUser,
  edit: EditUser,
  options: { label: 'Usuarios'}
};