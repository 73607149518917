import React from 'react';
import { Layout } from 'react-admin';
import ErrorCustom from '../ErrorCustom/ErrorCustom';
import MenuCustom from './MenuCustom';
import AppbarCustom from './AppbarCustom';
import { useTheme } from '@mui/material';

const LayoutCustom = (props) => {
  const theme = useTheme();

  return (
    <Layout
      {...props}
      error={ErrorCustom}
      menu={MenuCustom}
      appBar={AppbarCustom}
      // sidebar={SidebarCustom}
      sx={{ backgroundColor: theme.palette.primary.light }}
    />
  );
};

export default LayoutCustom;