import { Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';

const TextFieldBase = ({ label, formatRecord, source, fontSize = '16px', style, onClick = false }) => {
  const recordCtx = useRecordContext();

  return (
    <Typography
      variant='subtitle2'
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: fontSize,
        ...style
      }}
      label={label}
      onClick={() => onClick && onClick(recordCtx[source])}
    >
      {
        formatRecord ? formatRecord(recordCtx[source]) : recordCtx[source]
      }
    </Typography>
  );
};

export default TextFieldBase;