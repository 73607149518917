import { MdOutlineStore } from 'react-icons/md';
import ListBranch from './ListBranch';
import CreateBranch from './Form/CreateBranch';
import EditBranch from './Form/EditBranch';

export default {
  name: 'branches',
  list: ListBranch,
  icon: MdOutlineStore,
  create: CreateBranch,
  edit: EditBranch,
  options: {label: 'Sucursales'}
};