import React from 'react';
import { AutocompleteInput } from 'react-admin';

const AutocompleteInputCustom = ({ ...rest }) => {
  return (
    <AutocompleteInput
      color='primary.main'

      sx={{
        maxWidth: 360,
        color: '#000',
        '& label.Mui-focused': {
          color: '#000',
        },
        '& label': {
          color: 'gray',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'gray',
        },
        '& .MuiSelect-icon': {
          color: 'gray',
        },
        '& .RaSelectArrayInput-chip': {
          backgroundColor: 'primary.main',
        },
        '& .MuiOutlinedInput-root': {
          color: '#000',
          '& fieldset': {
            borderRadius: 2,
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: 'gray',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'secondary.main',
          },
        },
      }}
      {...rest}
    />
  );
};

export default AutocompleteInputCustom;