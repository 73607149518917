import { Box, IconButton, InputAdornment } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DateRangePicker } from 'react-date-range';
import EventIcon from '@mui/icons-material/Event';
import FilterInput from '../InputsCustom/FilterInput/FilterInput';
import { es, enUS } from 'date-fns/esm/locale';

const PickerDate = ({ onChange, date }) => {
  const [showPicker, setShowPicker] = useState(false);
  const datePickerRef = useRef(null);
  const modalPickerRef = useRef(null);

  const handleNextDay = () => {
    const datejs = dayjs(date).add(1, 'day');
    onChange(datejs);
  };

  const handlePrevDay = () => {
    const datejs = dayjs(date).subtract(1, 'day');
    onChange(datejs);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (datePickerRef.current && modalPickerRef.current &&
        !datePickerRef.current.contains(event.target) &&
        !modalPickerRef.current.contains(event.target)
      ) {
        setShowPicker(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>

      <IconButton onClick={handlePrevDay} size="small" color={'text.contrastText'}>
        <KeyboardArrowLeftIcon style={{ fontSize: 24 }} />
      </IconButton>

      <Box sx={{ position: 'relative' }} ref={datePickerRef} >
        <FilterInput
          readOnly
          disabled
          value={dayjs(date).format('DD/MM/YYYY')}
          color='#3d3d3d'
          sx={{
            width: '160px',
          }}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  sx={{ zIndex: 10, color: 'rgba(0,0,0,.54)' }}
                  onClick={() => setShowPicker(prev => !prev)}
                  edge="end"
                >
                  <EventIcon />
                </IconButton>
              </InputAdornment>

          }}

        />
        <Box ref={modalPickerRef}>
          {showPicker && <DateRangePickerPosition onChange={onChange} date={date} setShow={setShowPicker} />}
        </Box>
      </Box>

      <IconButton onClick={handleNextDay} size="small" color={'text.contrastText'}>
        <KeyboardArrowRightIcon style={{ fontSize: 24 }} />
      </IconButton>

    </Box>
  );
};

const DateRangePickerPosition = ({ onChange, date, setShow }) => {

  const handleChange = ({ date }) => {
    onChange(date.startDate);
    setShow(false);
  };

  return (
    <Box
      sx={{
        '.rdrCalendarWrapper': {
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px'
        },
        '.rdrDefinedRangesWrapper': {
          display: 'none'
        },
        zIndex: 9,
        position: 'absolute',
        top: 44,
        right: 0,
      }}
    >
      <DateRangePicker
        showMonthArrow={true}
        showMonthAndYearPickers={false}
        preventSnapRefocus={true}
        editableDateInputs={false}
        locale={es}
        showDateDisplay={false}
        ranges={[{ startDate: new Date(date), endDate: new Date(date), key: 'date' }]}
        onChange={handleChange}
        style={{ borderRadius: '12px' }}
      />
    </Box>
  );
};
export default PickerDate;