import { Box } from '@mui/material';
import TitleResource from '../TitleResource/TitleResource';
import { CreateButton } from 'react-admin';

const TopToolbarList = ({ title, hasCreate, labelBtn, icon }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 4, }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        {
          icon && icon
        }
        <TitleResource title={title} />
      </Box>
      {
        hasCreate && <CreateButton label={labelBtn} color='secondary' />
      }
    </Box>
  );
};

export default TopToolbarList;